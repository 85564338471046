@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bodoni+Moda:opsz@6..96&family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400&display=swap');
@font-face {
  font-family: "KeplerStdMediumDisp";   /*Can be any text*/
  src: local("KeplerStd-MediumDisp"),
    url("./assets/fonts/KeplerStd-MediumDisp.otf") format("opentype");
}
@font-face {
  font-family: "KeplerStd-Light";   /*Can be any text*/
  src: local("KeplerStd-Light"),
    url("./assets/fonts/KeplerStd-Light.otf") format("opentype");
}
@font-face {
  font-family: "ZuumeBold";   /*Can be any text*/
  src: local("ZuumeBold"),
    url("./assets/fonts/ZuumeBold.ttf") format("truetype");
}
@font-face {
  font-family: "ZuumeMedium";   /*Can be any text*/
  src: local("ZuumeMedium"),
    url("./assets/fonts/ZuumeMedium.ttf") format("truetype");
}
@font-face {
  font-family: "LeituraSans-Italic2";   /*Can be any text*/
  src: local("LeituraSans-Italic2"),
    url("./assets/fonts/LeituraSans-Italic2.otf") format("opentype");
}
@font-face {
  font-family: "LeituraSans-Grot2";   /*Can be any text*/
  src: local("LeituraSans-Grot2"),
    url("./assets/fonts/LeituraSans-Grot2.otf") format("opentype");
}
@font-face {
  font-family: "LeituraSans-Grot1";   /*Can be any text*/
  src: local("LeituraSans-Grot1"),
    url("./assets/fonts/LeituraSans-Grot1.otf") format("opentype");
}

$black: #000000;
$gray: #2b2a2a;
$dark: #101010;
$white: #ffffff;
$light: #929292;

html, * {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: $white;
  font-family: 'Noto Sans JP', sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.container {
  width: 100%;
  max-width: 1640px;
  position: relative;
  margin: 0 auto;
  transition: .25s ease-in-out;
  @media (max-width: 1450px) {
    max-width: 1200px;
  }
  @media (max-width: 1386px) {
    max-width: unset;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: 575px) {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

main {
  width: 100%;
  min-height: 100vh;
  background: $black;
  header {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(-45deg, $dark 30%, $gray);
    transition: .3s ease-in-out;
    .nav {
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      transition: .2s ease-in-out;
      .nav-container {
        width: 100%;
        max-width: 1366px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .nav-social-media {
        width: 100%;
        height: 100%;
        display: flex;
        transition: .2s ease-in-out;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1rem;
          list-style: none;
          transition: .2s ease-in-out;
          li a {
            transition: .25s ease-in-out;
            svg {
              font-size: 1.3rem;
              fill: $light;
            }
            &:hover {
              svg {
                fill: $white;
              }
            }
          }
        }
      }
      .nav-logo {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        transition: .2s ease-in-out;
        img {
          width: auto;
          height: 80px;
          transition: .2s ease-in-out;
        }
      }
      .nav-contact {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        a {
          font-family: "KeplerStdMediumDisp";
          font-size: 1.2rem;position: relative;
          text-decoration: none;
          width: max-content;
          display: flex;
          position: relative;
          z-index: 1;
          transition: .3s ease-in-out;
          &:before {
            content: '';
            background: $white;
            width: 0;
            top: 0;
            left: -3px;
            right: 0;
            z-index: -1;
            bottom: 0;
            position: absolute;
            transition: .25s ease-in-out;
          }
          &:hover {
            color: $black;
            &:before {
              content: '';
              width: 84px;
            }
          }
        }
      }
      &.scrolling {
        width: 100%;
        margin: 0 auto;
        padding: 0 1rem;
        left: 0;
        right: 0;
        height: 60px;
        position: fixed;
        top: 0;
        z-index: 99;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(30px);
        .nav-social-media {
          position: relative;
          ul {
            li a {
              svg {
                font-size: 1rem;
              }
            }
          }
        }
        .nav-logo {
          align-items: center;
          img {
            height: 50px;
          }
        }
        .nav-contact {
          a {
            font-size: 1rem;
            &:hover {
              &:before {
                content: '';
                width: 72px;
              }
            }
          }
        }
      }
    }
    .section-header {
      width: 100%;
      height: calc(100vh - 150px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4rem;
      &-book {
        width: 100%;
        img {
          width: 100%;
          height: auto;
          filter: drop-shadow(0 0 1rem rgba(0,0,0,.5));
        }
      }
      &-info {
        width: 100%;
        display: flex;
        align-items:flex-end;
        flex-direction: column;
        h1 {
          width: 100%;
          max-width: 510px;
          font-family: "ZuumeMedium";
          font-size: 2.5rem;
          text-align: right;
        }
        p {
          width: 100%;
          max-width: 450px;
          text-transform: uppercase;
          font-family: "LeituraSans-Italic2";
          color: $light;
          font-size: 1.2rem;
          text-align: right;
        }
      }
    }
  }
  section {
    &.bio {
      width: 100%;
      display: flex;
      background: linear-gradient(45deg, $dark 30%, $gray);
      .bio-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: linear-gradient(45deg, $dark 30%, $gray);
      }
      .bio-info {
        width: 100%;
        display: flex;
        padding: 5rem 2rem;
        flex-direction: column;
        justify-content: center;
        h2 {
          width: 100%;
          max-width: 450px;
          text-align: left;
          font-size: 2.5rem;
          font-family: "ZuumeMedium";
          margin-bottom: .5rem;
          padding-left: 10rem;
        }
        p {
          width: 100%;
          font-size: 1.2rem;
          max-width: 570px;
          font-weight: 300;
          font-family: "LeituraSans-Grot2";
          padding-left: 10rem;
        }
        i{
          font-size: 1.2rem;
          font-weight: 300;
          font-family: "LeituraSans-Grot2";
        }
      }
      .bio-img {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: auto;
          height: 700px;
          object-fit: cover;
        }
      }
    }
    &.book {
      width: 100%;
      height: max-content;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4rem 0;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center !important;
      .book-container {
        width: 100%;
        height: max-content;
        display: flex;
        justify-content: space-between;
      }
      .book-info {
        width: 95%;
        display: flex;
        padding: 5rem 2rem;
        flex-direction: column;
        // justify-content: center;
        align-items: flex-end;
        h2 {
          width: 100%;
          text-align: right;
          font-size: 2.5rem;
          font-family: "ZuumeMedium";
          margin-bottom: .5rem;
        }
        h3 {
          font-size: 1.2rem;
          text-align: right;
          font-family: "LeituraSans-Grot1";
          font-weight: 300;
          margin-bottom: 2rem;
        }
        &-img {
          img {
            width: auto;
            height: 300px;
            object-fit: cover;
          }
        }
      }
      .book-text {
        width: 100%;
        display: flex;
        justify-content: left;
        padding-top: 5.5rem;
        p {
          font-family: "LeituraSans-Grot2", sans-serif;
          width: 100%;
          max-width: 400px;
          font-size: 1.2rem;
          font-weight: 300;
          font-weight: 300;
        }
        i{
          font-family: "LeituraSans-Grot2";
          width: 100%;
          max-width: 400px;
          font-size: 1.2rem;
          font-weight: 300;
        }
      }
    }
  }
  footer {
    width: 100%;
    padding: 7rem 0;
    display: flex;
    background: linear-gradient(to top, $black, $dark);
    .footer {
     width: 100%;
     display: flex;
      justify-content: space-between;
     .center {
       width: 100%;
       height: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       img {
         width: auto;
         height: 40px;
       }
     }
     .left {
       width: 100%;
       height: 100%;
       display: flex;
       img {
         width: auto;
         height: 42px;
       }
       a {
        text-decoration: none;
         width: 100%;
         display: flex;
         align-items: flex-start;
         text-align: left;
         justify-content: center;
         font-family: 'Bodoni Moda', sans-serif;
         flex-direction: column;
         margin-left: 1rem;
         font-size: .9rem;
       }
     }
     .right {
       width: 100%;
       height: 100%;
       display: flex;
       justify-content: flex-end;
       align-items: center;
       a {
        text-decoration: none;
         text-align: right;
         font-size: 1rem;
         display: flex;
         font-family: "KeplerStd-Light";
         align-items: flex-end;
         justify-content: center;
         flex-direction: column;
         &:hover {
           text-decoration: underline;
         }
       }
      }
    }
  }
}

@media (max-width: 992px) {
  main {
    header {
      background: linear-gradient(-45deg, $dark 30%, $gray);
      .nav {
        width: 100%;
        height: 100px;
        .nav-social-media {
          ul {
            gap: .5rem;
            li a {
              svg {
                font-size: 1rem;
              }
            }
          }
        }
        .nav-logo {
          img {
            height: 50px;
          }
        }
        .nav-contact {
          a {
            font-size: 1rem;
            &:hover {
              &:before {
                content: '';
                width: 72px;
              }
            }
          }
        }
      }
      .section-header {
        flex-direction: column;
        padding: 5rem 0;
        height: calc(80vh);
        &-book {
          display: flex;
          justify-content: center;
          img {
            width: auto;
            height: 300px;
            margin: 0 auto;
          }
        }
        &-info {
          h1 {
            max-width: 450px;
            font-size: 2rem;
          }
          p {
            max-width: 450px;
            font-size: 1rem;
          }
        }
      }
    }
    section {
      &.bio {
        .bio-container {
          flex-direction: column-reverse !important;
        }
        .bio-info {
          width: 100%;
          display: flex;
          padding: 5rem 2rem;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          h2 {
            font-size: 2rem;
            line-height: 2.2rem;
            padding-left: 0rem;
            span {
              font-size: 1.2rem;
              line-height: 1.4rem;
              font-style: italic;
            }
          }
          p {
            font-size: 1.2rem;
            line-height: 1.4rem;
            padding-left: 0rem;
            span {
              font-size: 1.2rem;
              line-height: 1.4rem;
              font-style: italic;
            }
          }
        }
      }
      &.book {
        .book-container {
          justify-content: flex-start;
          flex-direction: column;
        }
        .book-info {
          width: 100%;
          padding: 0 2rem;
          align-items: flex-start;
          h2 {
            font-size: 4rem;
            text-align: left;
          }
          h3 {
            font-size: 2rem;
            text-align: left;
            font-weight: 100;
          }
          &-img {
            img {
              height: 400px;
            }
          }
        }
        .book-text {
          width: 100%;
          padding: 2rem 1rem 0  1rem;
          justify-content: center;
          p {
            max-width: 850px;
            font-size: 1.2rem;
            font-weight: 300;
            span {
              font-size: 1.2rem;
              font-weight: 400;
              font-style: italic;
            }
          }
        }
      }
    }
    footer {
      .footer {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        .logo {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: auto;
            height: 50px;
          }
        }
        .contact {
          justify-content: center;
          a {
            font-family: "Bodoni Moda", sans-serif;
            text-decoration: none;
            font-size: 1.2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: .4s ease-in-out;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .extra {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          img {
            height: 50px;
          }
          .left-bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 1rem;
            a {
              text-decoration: none;
              font-family: "Bodoni Moda", sans-serif;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  main {
    header {
      background: linear-gradient(-45deg, $dark 30%, $gray);
      height: auto;
      .nav {
        width: 100%;
        height: 150px;
        .nav-social-media {
          ul {
            display: flex;
            flex-direction: column;
            gap: 0rem !important;
            padding-left: 2rem;
            align-items: baseline;
            li a {
              svg {
                font-size: 1rem;
              }
            }
          }
        }
        .nav-logo {
          img {
            height: 50px;
          }
        }
        .nav-contact {
          a {
            font-size: 1rem;
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            padding-left: 2rem;
            &:hover {
              &:before {
                content: '';
                width: 16px;
              }
            }
          }
        }
        &.scrolling {
          height: 70px !important;
          .nav-social-media {
            ul {
              padding-left: 1.5rem;
              flex-direction: row;
              gap: .5rem !important;
            }
          }
          .nav-contact {
            a {
              font-size: 1rem;
              writing-mode: vertical-rl;
              transform: rotate(180deg);
              padding-left: 1rem;
              &:hover {
                &:before {
                  content: '';
                  width: 16px;
                  height: 60px;
                }
              }
            }
          }
          height: 90px;
        }
      }
      .section-header {
        flex-direction: column;
        height: calc(100vh - 300px);
        padding: 2rem 0;
        justify-content: space-evenly;
        &-book {
          display: flex;
          justify-content: center;
          img {
            width: auto;
            height: 300px;
            margin: 0 auto;
          }
        }
        &-info {
          padding: 2rem 2rem 5rem 3.5rem;
          h1 {
            max-width: 400px;
            font-size: 1.6rem;
          }
          p {
            max-width: 450px;
            font-size: 1.2rem;
          }
        }
      }
    }
    section {
      &.bio {
        .bio-container{
          padding-top: 3rem;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
        }
        .bio-info {
          padding: 1rem 2rem 2rem 2rem;
          align-items: flex-start;
          h2 {
            text-align: left;
            padding-left: 0;
            line-height: 3rem;
            font-size: 2.5rem;
            margin: 0 0 2rem ;
          }
          p{
            font-size: 1.2rem;
            padding-left: 0;
            padding-bottom: 2rem;
          }
        }
        .bio-img {
          justify-content: center;
          padding: 0rem 2rem 0rem 2rem;
          img {
            width: 100%;
            height: auto !important;
          }
        }
      }
      &.book {
        .book-container {
          justify-content: flex-start;
          flex-direction: column;
          padding: 0 2rem;
        }
        .book-info {
          width: 100%;
          padding: 0rem 0rem 2rem 0rem;
          align-items: flex-start;
          h2 {
            font-size: 4rem;
            text-align: left;
          }
          h3 {
            font-size: 2rem;
            text-align: left;
            font-weight: 100;
          }
          &-img {
            img {
              height: 400px;
            }
          }
        }
        .book-text {
          width: 100%;
          padding: 1rem 0.5rem 2rem 0.5rem;
          justify-content: center;
          p {
            font-size: 1.2rem;
            font-weight: 300;
            span {
              font-size: 1.2rem;
              font-weight: 400;
              font-style: italic;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  main {
    header {
      .section-header {
        &-book {
          img {
            height: 240px;
          }
        }
      }
    }
    section {
      &.bio {
        .bio-info {
          p{
            font-size: 1rem;
            i {
              font-size: 1rem;
            }
          }
        }
      }
      &.book {
        .book-info {
          h2 {
            font-size: 3rem;
            text-align: left;
          }
          h3 {
            font-size: 1.6rem;
          }
          &-img {
            img {
              height: 320px;
            }
          }
        }
        .book-text {
          width: 100%;
          padding: 0rem 0rem 2rem 0rem;
          justify-content: center;
          p {
            font-size: 1rem;
            i {
              font-size: 1rem;
            }
          }
        }
      }
    }
    footer {
      padding-top: 2rem;
      .footer {
        .logo {
          img {
            height: 40px;
          }
        }
        .contact {
          a {
            font-size: 1rem;
          }
        }
        .extra {
          img {
            height: 30px;
          }
          .left-bottom {
            margin-left: .25rem;
            a {
              font-size: .8rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 390px){
  main {
    header {
      .section-header {
        height: calc(100vh - 100px);
        &-book {
          img {
            height: auto;
            width: 240px;
          }
        }
      }
    }
  }
}
@media screen and (max-device-width: 480px){
  body{
    -webkit-text-size-adjust: 100%;
  }
}
